// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const lowerize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toLowerCase() + s.slice(1);
};

const initialState = {
  loading: false,
  status: 'idle',
  error: null,
  data: [],
  filteredData: [],
  groupedData: [],
  options: {
    programHeaders: [],
    serviceHeaders: [],
    onlineCourseHeaders: [],
    serviceItems: [],
    onlineCourseItems: [],
    branches: [],
    programNames: [],
    subscriptions: [],
    alumniEventProgramCategories: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchingReportsStart: (state) => {
      state.loading = true;
      state.status = 'loading';
    },
    fetchSalesReportsSuccess: (state, action) => {
      state.data = action.payload;
      state.filteredData = action.payload;
    },
    fetchOptionsSuccess: (state, action) => {
      state.options.programHeaders = action.payload.programs;
      state.options.serviceHeaders = action.payload.services;
      state.options.onlineCourseHeaders = action.payload.onlineCourses;
      state.options.serviceItems = action.payload.serviceItems;
      state.options.onlineCourseItems = action.payload.onlineCourseItems;
      state.options.branches = action.payload.branches;
      state.options.programNames = action.payload.programNames;
      state.options.subscriptions = action.payload.subscriptions;
      state.options.alumniEventProgramCategories =
        action.payload.alumniEventProgramCategories;
    },
    fetchAdminsSuccess: (state, action) => {
      state.admins = action.payload;
    },
    fetchSuccess: (state) => {
      state.loading = false;
      state.status = 'success';
    },
    fetchFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
    filteringSalesData: (state, action) => {
      const {
        category,
        productHeader,
        productItem,
        programHeader,
        admin,
        toDate,
        fromDate,
        status,
        branch,
      } = action.payload;

      let { data } = state;
      data = JSON.parse(JSON.stringify(data));
      let filtered = data;
      // filtering by branch
      if (branch) {
        /**
         * Filter program sales transactions based on the given branch ID.
         *
         * Specification: Only include program sales that belong to the specified branch.
         * This ensures that the filtered results contain transactions relevant to the selected branch.
         */
        filtered.forEach((e, i) => {
          filtered[i].programSales = e.programSales.filter(
            (v) => Number(v.itemId) === Number(branch), // Ensure itemId matches the provided branch ID
          );
        });

        // Note: Update this filtering logic if the specification changes in the future
        filtered = filtered.map((trx) => ({
          id: trx.id,
          createdBy: trx.createdBy,
          invoiceCreatedAt: trx.invoiceCreatedAt,
          ['programSales']: trx['programSales'],
        }));
      }

      // filtering by category
      const joined = category.split(' ').join('');
      const loweredCat = lowerize(joined);
      if (loweredCat) {
        filtered = data.map((trx) => {
          return {
            id: trx.id,
            createdBy: trx.createdBy,
            invoiceCreatedAt: trx.invoiceCreatedAt,
            [`${loweredCat}Sales`]: trx[`${loweredCat}Sales`],
          };
        });
      }

      // filtering by date
      if (fromDate || toDate) {
        filtered = filtered.filter((e) => {
          const date = e.invoiceCreatedAt || e.createdAt;
          return (
            moment(date).format('YYYY-MM-DD') >=
              moment(fromDate).format('YYYY-MM-DD') &&
            moment(date).format('YYYY-MM-DD') <=
              moment(toDate).format('YYYY-MM-DD')
          );
        });
      }
      // filtering by productHeaderId
      if (productHeader) {
        filtered.forEach((e, i) => {
          filtered[i][`${loweredCat}Sales`] = e[`${loweredCat}Sales`].filter(
            (v) =>
              Number(v.product_typeId || v.itemId) === Number(productHeader) ||
              v?.alumniEvents?.alumniEventCategories?.find(
                (vAlumniCategory) =>
                  vAlumniCategory.programCategoryName === productHeader,
              ),
          );
        });
      }

      // filtering by programNameId (programHeader)
      if (programHeader) {
        filtered.forEach((e, i) => {
          filtered[i][`${loweredCat}Sales`] = e[`${loweredCat}Sales`].filter(
            (v) => Number(v.programHeaderId) === Number(programHeader),
          );
        });
      }

      // filtering by productItemId
      if (productItem) {
        filtered.forEach((e, i) => {
          filtered[i][`${loweredCat}Sales`] = e[`${loweredCat}Sales`].filter(
            (v) => Number(v.itemId) === Number(productItem),
          );
        });
      }

      // filtering by adminUsername
      if (admin) {
        filtered = filtered.filter((e) => e.createdBy === admin);
      }

      // filtering by status
      if (status) {
        filtered = filtered.filter((e) => {
          if (status === 'paid') {
            return e.invoiceCreatedAt;
          }
          if (status === 'unpaid') {
            return !e.invoiceCreatedAt;
          }
          return true;
        });
      }

      state.filteredData = filtered;
    },
    groupingSalesData: (state) => {
      try {
        // group by category (eg. JC Fulltime, Workshop)
        const categoryResult = [{}, {}, {}, {}, {}];

        state.filteredData.forEach((val) => {
          const {
            programSales,
            onlineCourseSales,
            serviceSales,
            subscriptionSales,
            alumniEventSales,
          } = val;

          const groupingArr = [
            programSales,
            onlineCourseSales,
            serviceSales,
            subscriptionSales,
            alumniEventSales,
          ];

          groupingArr.forEach((sales, index) => {
            if (sales) {
              const grouping = sales.reduce((r, a) => {
                const productType =
                  (a.subscriptionId && 'Subscription') ||
                  (a.alumniEventId && 'Alumni Event') ||
                  a.product_type;

                r[productType] = [...(r[productType] || []), a];
                return r;
              }, {});
              Object.keys(grouping).forEach((prop) => {
                if (categoryResult[index][prop]) {
                  categoryResult[index][prop] = categoryResult[index][
                    prop
                  ].concat(grouping[prop]);
                } else {
                  categoryResult[index][prop] = grouping[prop];
                }
              });
            }
          });
        });
        // next group by product name (eg. JCWM, JCUX, JCDM)
        const productResult = [{}, {}, {}, {}, {}, {}];
        categoryResult.forEach((category, index) => {
          Object.keys(categoryResult[index]).forEach((property) => {
            const tempResult = {};
            const grouping = categoryResult[index][property].reduce((r, a) => {
              r[a.product] = [...(r[a.product] || []), a];
              return r;
            }, {});
            Object.keys(grouping).forEach((prop) => {
              if (tempResult[prop]) {
                tempResult[prop] = tempResult[prop].concat(tempResult[prop]);
              } else {
                tempResult[prop] = grouping[prop];
              }
            });
            productResult[index][property] = tempResult;
          });
        });
        Object.keys(productResult[0]).forEach((category) => {
          Object.keys(productResult[0][category]).forEach((program) => {
            const tempResult = {};
            const grouping = productResult[0][category][program].reduce(
              (r, a) => {
                r[a.branchName] = [...(r[a.branchName] || []), a];
                return r;
              },
              {},
            );
            Object.keys(grouping).forEach((prop) => {
              if (tempResult[prop]) {
                tempResult[prop] = tempResult[prop].concat(tempResult[prop]);
              } else {
                tempResult[prop] = grouping[prop];
              }
            });
            productResult[0][category][program] = tempResult;
          });
        });

        state.groupedData = productResult;
      } catch (err) {
        console.log(err);
      }
    },
  },
});

export default reducer;

export const {
  fetchingReportsStart,
  fetchSalesReportsSuccess,
  fetchOptionsSuccess,
  fetchAdminsSuccess,
  fetchSuccess,
  fetchFailure,
  filteringSalesData,
  groupingSalesData,
} = actions;

export const initialFetch = () => async (dispatch) => {
  dispatch(fetchingReportsStart());
  try {
    const programs = await axios.get(`/admin/program/categories`);
    const services = await axios.get(`/admin/service/categories`);
    const onlineCourses = await axios.get(`/online-course/categories`);
    const branches = await axios.get(`/branch/all`);
    const serviceItems = await axios.get(`/admin/service/list`);
    const onlineCourseItems = await axios.get(`/online-course/category-topics`);
    const programNames = await axios.get(`/admin/program/all-header-names`);
    const subscriptions = await axios.get('/v2/subscription');
    const alumniEventProgramCategories = await axios.get(
      '/v2/alumni-event-program-category',
    );
    dispatch(
      fetchOptionsSuccess({
        programs: programs.data.result,
        services: services.data.result,
        onlineCourses: onlineCourses.data.result,
        branches: branches.data.result,
        serviceItems: serviceItems.data.result,
        onlineCourseItems: onlineCourseItems.data.result,
        programNames: programNames.data.result,
        subscriptions: subscriptions.data.data,
        alumniEventProgramCategories: alumniEventProgramCategories.data.data,
      }),
    );
    dispatch(fetchSuccess());
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchReports = (params) => async (dispatch) => {
  dispatch(fetchingReportsStart());
  try {
    const options = {
      params: {
        fromDate: params.fromDate,
        toDate: params.toDate,
      },
    };
    const { data } = await axios.get(
      `/admin/report/allsalestransactions`,
      options,
    );
    dispatch(fetchSalesReportsSuccess(data.result));
    dispatch(filteringSalesData(params));
    dispatch(groupingSalesData());
    dispatch(fetchSuccess());
  } catch (err) {
    console.log(err);
    dispatch(fetchFailure(err));
  }
};

export const filterReports = (params) => (dispatch) => {
  try {
    dispatch(filteringSalesData(params));
    dispatch(groupingSalesData());
    dispatch(fetchSuccess());
  } catch (err) {
    console.log(err);
    dispatch(fetchFailure(err));
  }
};
